@import "styles/base.scss";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;400;500;600;700;800&display=swap");

body {
    margin: 0;
    background: #e5ebed;
    font-family: Public Sans, sans-serif;
    text-align: left;
}

.relocation {
    margin-top: 32px;
    width: 1440px;
}

// Utility classes
.d-flex-center {
    display: flex;
    justify-content: center;
}
