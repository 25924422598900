.caseHeader {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.reservationHeader {
    display: grid;
    grid-template-columns: 1fr 2fr;
    max-width: 480px;
    max-height: 120px;
}

.reservationHeaderSubTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #2c3439;
}

.reservationHeaderText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #2c3439;
}
.reservationHeaderLink {
    color: #4695bc;
}

.caseMail {
    background: #ccd6db;
}

.noTextDecoration {
    text-decoration: none;
    color: #4695bc;
}

.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 50px;
    top: 130px;
}

.titleCompleted {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 10px;
    top: 130px;
}

.subTitleCompleted {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin-left: 50px;
    top: 130px;
    text-decoration-line: line-through;
    color: #2c3439;
}

.subTitle {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    top: 130px;
}

.subTitleInProgress {
    color: #6ba342;
    font-style: italic;
}

.subTitleNotInitiated {
    color: #d62e4f;
    font-style: italic;
}
