.headerCases {
    width: 1273px;
    height: 34px;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    margin-bottom: 17px;
    display: grid;
    grid-template-columns: 0.25fr 1.9fr 1.2fr 1fr 1fr 2fr 2fr 1fr 1fr;
    grid-gap: 10px;
}

.headerReport {
    width: 1273px;
    height: 34px;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    margin-bottom: 17px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    padding-left: 23px;
}

.headerItem {
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2c3439;
    margin-top: 5px;
    padding-left: 10px;
}

.headerItemSearchable {
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2c3439;
    margin-top: 5px;
    position: relative;
    cursor: pointer;
}

.noResults {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: #4d7080;
}

.paginationContainer {
    margin-left: -32px;
    background: #e5ebed;
    margin-top: 20px;
    margin-left: -32px;
    margin-bottom: -32px;
    margin-right: -32px;
    padding: 20px 0px;
}

.adjustPriorityLeft {
    padding-left: 15px;
}
