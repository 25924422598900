.caseStatus {
    margin-top: 24px;
}

.caseStatusTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.caseStatusInput {
    padding-top: 0.3em;
}

.caseStatusDdl {
    height: 40px;
    width: 160px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #969a9c;
    padding-left: 16px;
}
