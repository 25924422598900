.caseUnitReservation {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 27px;
    padding-top: 32px;
    padding-bottom: 32px;
}

hr {
    color: #ccd6db !important;
}

.caseUnitReservationInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 32px;
}
.caseUnitReservationTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
}
.caseUnitReservationSubTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.2px;
    color: #2c3439;
    margin-top: 32px;
}

.caseUnitReservationContentTitle {
    margin-top: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}

.caseUnitReservationContentText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
}

.caseUnitReservationContentTextLink {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.caseUnitReservationContentInput {
    padding-top: 0.3em;
}

.caseUnitReservationContentInputText {
    width: 146px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #b9c7cc;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #969a9c;
}

.caseUnitReservationContentLink {
    color: #4695bc;
}

.caseUnitReservationContentSaveBtn {
    color: #4695bc;
    padding-left: 20px;
}
.caseUnitReservationContentSaveBtn:hover {
    cursor: pointer;
}

.caseUnitReservationBold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.caseUnitReservationFinanceInfo {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-bottom: 32px;
}

.noTextDecoration {
    text-decoration: none;
}
