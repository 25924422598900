.caseSubmissionDetails {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 27px;
    padding-top: 32px;
}

hr {
    color: #ccd6db !important;
}

.caseSubmissionDetailsInfoGrid1x3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 32px;
}

.caseSubmissionDetailsInfoGrid1x2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 32px;
}

.caseSubmissionDetailsInfoFlat {
    padding-top: 32px;
    padding-bottom: 32px;
}

.caseSubmissionDetailsTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
    padding-bottom: 32px;
}

.caseSubmissionDetailsSubTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
}

.caseSubmissionDetailsContentTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}

.caseSubmissionDetailsContentText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
    text-align: justify;
}

.caseSubmissionDetailsInputContainer {
    margin-top: 5px;
}

.caseSubmissionDetailsInput {
    margin-right: 10px;
}

.button {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    margin-top: 20px;
    width: 154px;
    height: 40px;
    background: #0078ab;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

.buttonDisabled {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    margin-top: 20px;
    width: 154px;
    height: 40px;
    background: #707579;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}
