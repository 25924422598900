.overdueText {
    color: #d62e4f;
    font-weight: 400;
    font-family: Nunito-sans, sans-serif;
}

.skeleton {
    background: #ddd;
    margin-left: 10px;
    border-radius: 8px;
    animation: skeleton-animation 0.5s linear infinite alternate;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.smallText {
    width: 40%;
    height: 20px;
}

.skeleton.separator {
    width: 100%;
    height: 5px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}

@keyframes skeleton-animation {
    0% {
        background-color: hsl(200, 20%, 70%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}
