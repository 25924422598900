.body {
    border: 1px solid #ccd6db;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 16px;
    padding-top: 28px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 40px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;
    margin-bottom: 24px;
    padding-right: 10px;
}

.divSeparator {
    padding-bottom: 5px;
}

.subTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;
}

.selectBox {
    height: 40px;
    width: 296px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    padding-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.noEditText {
    text-align: justify;
    white-space: pre-wrap;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}

.textBox {
    height: 40px;
    width: 100%;
    padding-left: 16px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.textArea {
    text-align: justify;
    white-space: pre-wrap;
    height: 200px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    padding-left: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    resize: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.button {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 154px;
    height: 40px;
    background: #0078ab;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

.buttonDisabled {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 154px;
    height: 40px;
    background: #707579;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.section {
    margin-bottom: 32px;
    width: 600px;
}

.subTitleGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.link {
    color: #0078ab;
    cursor: pointer;
    text-decoration: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.checkboxGuest {
    height: 18px;
    width: 18px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
}

.textLabelGuest {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27221d;
}

.textLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #27221d;
}

.alignRight {
    text-align: right;
}

.editBtn {
    vertical-align: "middle";
    color: #707579;
    cursor: pointer;
}

.editBtnSuccess {
    vertical-align: "middle";
    color: #6ba342;
}

.textBoxWrapUp {
    height: 40px;
    width: 167px;
    padding-left: 16px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.paddingLeft {
    padding-left: 16px;
}

.textBoxWarning {
    border: 1px solid #d62e4f;
    &:focus {
        outline: none !important;
        border: 2px solid #d62e4f !important;
    }
}

.textBoxWarningText {
    color: #d62e4f;
}

input[type="search"] {
    margin: 0;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: inherit;
    border-radius: 4px;
}

input[type="search"]::placeholder {
    color: #969a9c;
    font-size: 16px;
    padding-top: 20px;
}

input[type="search"]:focus {
    outline: none;
}
.inputSearch {
    width: 400px;
    height: 40px;
    border: 1px solid #b2c2c8;
    padding: 11px 4px 9px 40px;
    background: transparent
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23707579' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg>")
        no-repeat 13px center;
}

.savingProgress {
    color: #0078ab;
    display: inline-block;
    margin-left: 5px;
    margin-top: 8px;
}

.btnBox {
    display: flex;
}

.wrapUpDivButton {
    display: flex;
}

.selectorInfo {
    background: #e5ebed;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    color: #00334996;
    border: 1px solid #ccd6db;
    width: 600px;
    svg {
        margin-right: 10px;
        vertical-align: text-bottom;
        font-size: 20px;
    }
}

.agentNoteContainer {
    display: flex;
    flex-direction: column;
}

.addUnitsButton {
    margin-left: 30px;
    color: #0078ab;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 16px;
}

.disabledClass {
    color: #ebebe4;
    cursor: no-drop;
}

.unitsLimit {
    color: #d62e4f;
    font-size: 12px;
    margin-left: 5px;
}

.splitstayContainer {
    background-color: #f5f7f8;
    padding: 1.5rem;
    width: 30.25rem;
    border-radius: 4px;

    &:nth-child(-n + 5) {
        margin-top: 1.6rem;
    }
}

.datesContainer {
    display: flex;
    margin-top: 1.5rem;
}

.dates {
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
        margin-left: 1.6rem;
    }
    & input {
        width: 100px !important;
        height: 25px !important;
        text-align: right !important;
        font-size: 1rem !important;
    }
}

.datesTitle {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
    margin-bottom: 0.5rem;
}

.bookingTypeContainer {
    margin: 2rem 0 2rem 0;
}

.bookingTypeTitle {
    color: #2c3439;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.vacasaUnitSection {
    display: flex;
    flex-direction: column;
}

.outsideBookingSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.subTitles {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.urlOutsideBooking {
    margin-top: 23px;
}

.textAreaSplitStay {
    text-align: justify;
    white-space: pre-wrap;
    height: 81px;
    width: 30.25rem;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    padding-left: 16px;
    padding-top: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    resize: none;
    margin-top: 15px;
    margin-bottom: 30px;
}

.unitNumber {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 18px;
}
