.caseGuestDetails {
    margin-top: 32px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 27px;
}

.caseGuestDetailsTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 32px;
}

.caseGuestDetailsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.caseWarningInfoIcon {
    padding-bottom: 3px;
    padding-right: 10px;
}

.caseGuestDetailsContainerItem {
    padding-bottom: 32px;
}

.caseGuestDetailsContainerItemTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}

.caseGuestDetailsContainerItemDetail {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
}

.caseGuestDetailsContainerItemDetailWarning {
    color: #d62e4f;
}
