.title {
    display: grid;
    grid-template-columns: 6fr 2fr;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #2c3439;
}
.time {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #707579;
}

.note {
    width: 459px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(204, 214, 219, 0.75);
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 24px 12px 24px;
    margin-top: 3px;
}
.noteLink {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #0078ab;
    cursor: pointer;
}

.subNote {
    line-height: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #4c5357;
}
