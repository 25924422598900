.component {
    display: block;
}

.checkBox {
    height: 18px;
    width: 18px;
    left: 0px;
    top: 0px;
    border-radius: 3px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
}

.iconSuccess {
    color: #6ba342;
}
.iconDanger {
    color: #d62e4f;
}

.dvNotes {
    margin-left: -24px;
    margin-top: 30px;
}
.dvNotesTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    margin-bottom: 0.3em;
}
.dvNotesTextArea {
    min-height: 81px;
    width: 100%;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    white-space: pre-wrap;
    padding: 11px 16px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    resize: none;
    justify-content: flex-start !important;
    padding-left: 22px;
}

.dvNotesTextAreaValid {
    border: 1px solid #b2c2c8;
    &:focus {
        outline: none !important;
        border: 2px solid #b2c2c8 !important;
    }
}

.dvNotesTextAreaInvalid {
    border: 1px solid #d62e4f;
    &:focus {
        outline: none !important;
        border: 2px solid #d62e4f !important;
    }
}

.dvNotesBtn {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    border: none;
}

.dvNotesBtnAllowed {
    background: #0078ab;
    cursor: pointer;
}

.dvNotesBtnDisabled {
    background: #707579;
}

.divSection {
    margin-top: 0.3em;
    display: flex;
    align-items: center;
    & > label {
        margin-left: 4px;
    }
}
.divSectionLink {
    margin-top: 10px;
}

.section {
    margin-top: 40px;
    margin-left: -24px;
}

.dueText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}

.dueTextInactive {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
}

.searchingProgress {
    color: #0078ab;
    display: inline-block;
    margin-left: 5px;
    margin-top: 36px;
}

.btnBox {
    display: flex;
}
