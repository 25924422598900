// Links
.linkContainer {
    display: flex;
    margin-left: auto;
    margin-right: -6px;
    a {
        font-size: 0.875rem;
        border-bottom: 1px solid #ccd6db;
        color: #4c4c4c;

        padding: 16px 12px;
        width: 160px;
        text-align: center;
    }
    .activeLink {
        border-bottom: 2px solid #8099a4;
        color: #003349;
        font-weight: bold;
    }

    .myCases {
        position: relative;
        .badge {
            position: absolute;
            background: #e26c83;
            color: #fff;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            line-height: 1.8;
            left: 70px;
            top: -25px;
            font-weight: normal;
        }
    }
}

.headerReport{
  width: 1273px;
  height: 34px;
  background: #e5ebed;
  box-shadow: 0 -1px 0 #b2c2c8, 0 1px 0 #b2c2c8;
  margin-bottom: 17px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-gap: 10px;
  padding-left: 23px;
}

.headerItem{
  font-family: "Public Sans",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2c3439;
  margin-top: 5px;
}

// Container
.container {
    width: 1360px;
    padding-left: 32px;
    border-radius: 4px;
    .inner {
        background: #ffffff;
        margin-top: 32px;
        min-height: 340px;
        padding: 32px;
        .subheader {
            display: flex;
            margin-bottom: 23px;
            margin-top: 20px;
            .filters {
                display: flex;
            }
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.3px;
            color: #2c3439;
            margin-bottom: 36px;
        }
    }
    .refreshBtnSection {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #0078ab;
        margin-right: 32px;
        margin-top: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .titleSection {
        display: flex;
        justify-content: space-between;
    }
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.rotating {
    animation: spin 1s infinite linear;
}

.refreshText {
    margin-left: 11px;
}

.filterSection {
    margin-left: 32px;
    margin-top: 32px;
    display: flex;
    width: 1296px;
}

.filterSearch {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 3fr;
    grid-gap: 32px;
    margin-bottom: 26px;
}


.filterCheckbox {
    box-sizing: border-box;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    height: 18px;
    width: 18px;
    border-radius: 3px;
}
