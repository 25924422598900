.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 63px;
    top: 130px;
}

.arrow {
    margin-left: 32px;
    margin-top: 90px;
}
