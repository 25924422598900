/* Custom styles for Urgent FM Step */
.urgentContainer {
    & label {
        margin-bottom: 10px;
        display: block;
        margin-top: 22px;
        font-weight: 600;
    }
    & p:nth-of-type(2) {
        margin: 25px 0 25px 0;
        font-weight: 600;
    }
    & input[type="date"] {
        height: 40px;
        width: 167px;
        padding-left: 16px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        background: #ffffff;
        opacity: 0.7;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    & input[type="text"] {
        height: 40px;
        width: 167px;
        padding-left: 16px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        background: #ffffff;
        opacity: 0.7;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    & select {
        height: 40px;
        width: 296px;
        border-radius: 4px;
        background: #ffffff;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        padding-left: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    & textarea {
        text-align: justify;
        white-space: pre-wrap;
        height: 80px;
        width: 550px;
        background: #ffffff;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        resize: none;
        padding: 10px;
    }
}

.optionalText {
    margin-top: 30px;
}
