.reloFileUploader {
    margin-top: 32px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 27px;
}

.reloFileUploaderTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 32px;
}

.reloFileUploaderContainer {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 20px;
}

.reloFileUploaderContainerV2 {
    @extend .reloFileUploaderContainer;
    overflow: hidden;
}

.filesTables thead {
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
}

.filesTables thead th {
    padding: 10px;
}

.filesTables tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
}

.filesTables tbody tr {
    background: #f5f7f8;
    border-radius: 4px;
}

.filesTables tbody tr:nth-child(odd) {
    background: #f0f2f3;
}

.filesTables tbody tr td {
    padding: 10px 30px;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0078ab;
}
