.body {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 4px;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 36px;
    padding-bottom: 32px;
}

hr {
    color: #ccd6db !important;
}

.Title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    margin-bottom: 24px;
}

.SubTitle1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #2c3439;
}

.SubTitle2 {
    margin-top: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: #2c3439;
}

.SubTextGray {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
    margin-bottom: 32px;
}

.modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
    z-index: 10000000 !important;
}

.modalContent button {
    padding: 8px 50px 8px 50px;
    background-color: #6ba342;
    color: #fff;
    border: none;
    margin-bottom: 0.6rem;
}

.modalContent a {
    margin-top: 1rem;
}

.modalContent label {
    margin: 2px 0 0 5px !important;
}

.titleMessage {
    word-break: break-word;
}

.descriptionMessage {
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.descriptionMessageOld {
    word-break: break-word;
}

.imgBody {
    max-width: 100%;
    width: 137px;
    margin-bottom: 1rem;
}
