@import "../../styles/mixins";

.body {
    background: #ffffff;
    margin-top: 32px;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 32px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 30px;
}

.titleNoTop {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-bottom: 30px;
}

.subTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 30px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.containerFinances {
    display: grid;
    grid-template-columns: 2fr 8fr 8fr;
}

.financesColumn {
    display: grid;
    grid-template-columns: 8fr;
}

.financesColumnWithTitle {
    display: grid;
    grid-template-columns: 2fr 8fr;
}

.containerFinancesTemp {
    display: grid;
    grid-template-columns: 5fr 4fr;
}

.containerAmounts {
    display: grid;
    grid-template-columns: 2fr 16fr;
}

.containerItem {
    padding-bottom: 32px;
}

.containerItemTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: auto;
}

.containerItemSubTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.containerItemDetail {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
    white-space: pre-wrap;
}
.containerItemDetailLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #707579;
}

.containerItemDetailLink {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0078ab;
}

.containerItemDetailWarning {
    color: #d62e4f;
}
.containerItemDetailGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.containerItemDetailMessage {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2c3439;
}

.button {
    @include button;
    width: 154px;
}

.buttonLarge {
    @include button;
    width: 200px;
}

.buttonLargeSecondary {
    @include button-secondary;
    width: 200px;
}

.buttonDisabled {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 154px;
    height: 40px;
    background: #707579;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.selectBox {
    height: 40px;
    width: 296px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    padding-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.selectBoxInternal {
    height: 40px;
    width: 296px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    padding-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #969a9c;
}

.divSeparator {
    padding-bottom: 5px;
}

.containerAlert {
    display: grid;
    grid-template-columns: 1fr 19fr;
    background: #ffffff;
    padding-bottom: 18px;
    border: 1px solid #ccd6db;
    box-shadow: 0 2px 10px rgba(44, 52, 57, 0.15);
    border-radius: 4px;
    margin-bottom: 32px;
    padding-left: 20px;
    padding-top: 18px;
    border-left: 4px solid #d62e4f;
}

.alertDiv {
    padding-bottom: 32px;
}

.contentDiv {
    padding-bottom: 32px;
}

.contentSeparatorDiv {
    padding-bottom: 32px;
}

.contentSeparatorHr {
    margin-bottom: 32px;
}

.contentTextBox {
    height: 40px;
    width: 167px;
    padding-left: 16px;
    padding-right: 8px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;
    text-align: right;
}

.prefix {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    margin-right: -13px;
    color: #2c3439 !important;
}

.contentTextArea {
    text-align: justify;
    white-space: pre-line;
    height: 200px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    padding-left: 16px;
    padding-top: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4c5357;
}

.noEditText {
    text-align: justify;
    white-space: pre-wrap;
    width: 100%;
}

.contentCheckbox {
    height: 18px;
    width: 18px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
}

.contentRadioButton {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #373737;
}

.radioButton {
    background: #fbfbfc;
    border: 2px solid #99adb6;
    margin-right: 10px;
    margin-left: 0;
}

.verificationDetailsText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27221d;
}

.alertDivText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4c5357;
}

.doneDiv {
    margin-top: 32px;
    display: flex;
}

.nonValid {
    border-color: #d62e4f !important;
    color: #d62e4f;
}

.financesTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    text-align: center;
    margin-bottom: 16px;
}

.flexTitle {
    display: flex;
    position: relative;
}

.cancelTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    text-align: left;
    margin-bottom: 16px;
}

.financesInnerTable {
    display: grid;
    grid-template-columns: 2fr 8fr 8fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.financesInnerTableOnlyData {
    display: grid;
    grid-template-columns: 8fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.cancelRefundInnerTable {
    display: grid;
    grid-template-columns: 2fr 16fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.financesInnerTableTitle {
    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
}
.financesInnerTableText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2c3439;
}

.financesInnerTableTextAlt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
    padding-right: 14px;
}

.financesInnerTableTextBold {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #000000;
}

.financesInnerTableAlert {
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 12px;
    background: #f5f7f8;
    border-radius: 4px;
}

.financesInnerTableAlertHighlight {
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 12px;
    background: #f5f7f8;
    border-radius: 4px;
    border: 1px solid #ccd6db;
    border-radius: 4px;
}

.financesInnerTableLink {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0078ab;
}

.ownerTextBox {
    height: 40px;
    width: 167px;
    padding-left: 16px;
    padding-right: 8px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;
}

.finalDetailsSubtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.2px;
    color: #2c3439;
}

.label {
    color: #2c3439;
    font-size: small;
}

.bold {
    font-weight: 700 !important;
}

.custom_input {
    display: flex;
    flex-direction: row;
    width: 167px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;

    &:focus-within {
        outline: #b2c2c8;
        border: 2px solid #b2c2c8;
    }

    & &_text_field {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    & &_input {
        text-align: center;
        font-size: 16px;
        color: #2c3439;
        height: 36px;
        width: 100px;
        padding-left: 16px;
        padding-right: 8px;
        background: #ffffff;
        border: none;
        outline: none;
    }

    &_decoration {
        display: flex;
        justify-content: center;
        color: #2c3439;
        font-weight: bold;
        align-items: center;
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;
        min-width: max-content;
        width: 40%;
        height: 38px;
        font-size: 16px;
        letter-spacing: 0.02857em;
        background: #e5ebed;
        border-radius: 0 4px 4px 0;
    }

    & > div {
        flex-grow: 1;
    }
}

.selectorInfo {
    background: #e5ebed;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    color: #00334996;
    border: 1px solid #ccd6db;
    width: 600px;
    svg {
        margin-right: 10px;
        vertical-align: text-bottom;
        font-size: 20px;
    }
}

.savingProgress {
    color: #0078ab;
    display: flex;
    margin-left: 5px;
    margin-top: 8px;
}

.savingProgressText {
    color: #0078ab;
    display: inline-block;
    margin-left: 5px;
    margin-top: 4px;
}

.link {
    cursor: pointer;
    text-decoration: none;
    color: #0078ab;
}

.positiveValue {
    color: #6ba342;
}

.negativeValue {
    color: #d62e4f;
}

.contentSaveBtn {
    color: #4695bc;
    padding-left: 20px;
}
.contentSaveBtn:hover {
    cursor: pointer;
}
.contentSaveBtnPercent {
    margin-top: 10px;
}

.arrowCircle {
    box-sizing: border-box;
    background: #ffffff;
    border: 0.5px solid #e5ebed;
    box-shadow: 0 0 4px rgba(44, 52, 57, 0.08);
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

.arrowLink {
    text-decoration: none;
    color: #27221d;
    vertical-align: middle;
    margin-bottom: 2px;
}

.leftArrow {
    position: absolute;
    left: 10px;
}

.rightArrow {
    position: absolute;
    right: 10px;
}
