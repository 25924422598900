@import "../../styles/mixins.scss";

.container {
    padding-left: 50px;
    a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #0078ab;
    }
    button {
        @include button;
        width: 154px;
    }
    select {
        @include select;
        width: 420px;
        margin-top: 15px;
    }
    input {
        @include input;
        width: 110px;
    }
}

.containerBlocked {
    width: 400px;
    margin: auto;
    margin-top: 100px;
}

.financeBox {
    display: inline-flex;
    padding: 10px;
    background: white;

    .financeTypeColumn {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0px 10px;

        > div {
            height: 52px;
            line-height: 3;
            width: 80px;
        }
    }

    .financeAmountColumn {
        .title {
            background: #e5ebed;
            box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
            font-size: 16px;
            font-weight: 700;
            height: 44px;
            line-height: 2.8;
            text-align: center;
            margin: initial;
            border-radius: initial;
            width: 208px;
            padding: initial;
        }

        > div {
            height: 48px;
            width: 200px;
            text-align: right;
            background: #f5f7f8;
            line-height: 2.7;
            border-radius: 4px;
            margin: 6px 4px;
            padding-right: 10px;
        }
    }
}
