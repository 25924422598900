.filterActionSelect {
    font-family: "Nunito Sans", sans-serif;
    height: 43px;
    width: 210px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding-left: 10px;
    color: #2c3439;
    outline: none;
    margin-right: 22px;
}

.selectAllCases {
    width: 50px;
    padding: 13px;
}

.inputCheck {
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    appearance: none;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid #99adb6;
    border-radius: 4px;
    outline: none;
}
