.label {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: "salt" on, "ss01" on;
    color: #2c3439;
    margin-left:15px;
    line-height: 40px;
}