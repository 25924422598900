/* Custom styles for Unavailable Dates Step */
.datesQuestion {
    font-weight: 600;
    line-height: 24px;
    margin: 27px 0 1px 0;
}

.noteText {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 11px !important;
    margin-top: 3px !important;
}

.moveGuestQuestion {
    color: #000;
    margin-top: 11px;
}
