.holidayContainer {
    width: 1360px;
    padding-left: 32px;
    border-radius: 4px;
}

.innerContainer {
    background: #ffffff;
    margin-top: 32px;
    padding-bottom: 40px;
    min-height: 700px;
}

.innerContainerTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
    padding-left: 32px;
    padding-top: 32px;
}

.titleSection {
    display: flex;
    justify-content: space-between;
}
