.tableItem {
    height: 48px;
    width: 1273px;
    margin-top: 8px;
    border-radius: 4px;
    background: #f5f7f8;
    display: grid;
    grid-template-columns: 0.25fr 1.9fr 1.2fr 1fr 1fr 2fr 2fr 1fr 1fr;
    grid-gap: 10px;
}

.itemWithFilter {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    display: flex;
    margin-top: 5px;
    text-align: center;
}

.lastItem {
    justify-self: end;
    margin-right: 18px;
    display: flex;
    margin-top: 16px;
}

.item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    margin-top: 12px;
}

.itemTask {
    display: flex;
    align-items: center;
}

.arrowLink {
    text-decoration: none;
    color: #27221d;
}

.assignedTo {
    cursor: pointer;
    margin-top: 7px !important;
}

.noDate {
    margin-left: 6px;
}

.customClassBadge {
    display: inline-block;
    width: 103px;
    box-sizing: border-box;
    height: 24px;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    padding: 0.35rem 0.65rem;
    font-family: Nunito-sans, sans-serif;

    &.noPriority {
        color: #ffffff !important;
        background-color: #003349 !important;
    }
    &.lowPriority {
        color: #000000 !important;
        background-color: #ffe700 !important;
    }
    &.mediumPriority {
        color: #000000 !important;
        background-color: #ffad02 !important;
    }
    &.highPriority {
        color: #000000 !important;
        background-color: #e26c83 !important;
    }
    &.isLTR {
        color: #000 !important;
        background-color: #a7c7e7 !important;
        width: 30px !important;
        height: 15px !important;
        font-size: 10px !important;
        margin: -25px 30px 0 -30px !important;
        text-align: center !important;
        line-height: normal !important;
        padding: 0.1rem !important;
    }
    &.isTAG {
        color: #000 !important;
        background-color: #a7c7e7 !important;
        width: 30px !important;
        height: 15px !important;
        font-size: 10px !important;
        margin: -25px 0 0 -30px !important;
        text-align: center !important;
        line-height: normal !important;
        padding: 0.1rem !important;
    }
}

.sourceBadge {
    @extend .customClassBadge;
    min-width: unset;
    justify-content: center;
    padding: 0.35rem 1.1rem;

    &.airbnbBadge {
        color: #fff !important;
        background-color: #f65960 !important;
    }
}

.timeDisplay {
    position: absolute;
    left: 210px;
    width: 80px;
    height: 16px;
    margin-left: 5px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4c5357;
}
.timeDisplayWarning {
    color: #d62e4f;
}

.inputCheckBox {
    margin: auto;
    align-content: center;
}

.inputCheck {
    font-family: "Nunito Sans", sans-serif;
    appearance: none;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid #99adb6;
    border-radius: 4px;
    outline: none;
    margin-left: 8px;
    margin-top: 15px;
}

.helpIndicator {
    cursor: help;
}
