.timeBox {
    height: 40px;
    width: 200px;
    padding-left: 16px;
    padding-right: 8px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;
    text-align: center;
    margin-left: 10px;
}

.container {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
}

.valid {
    border: 2px solid #0e8f12;
}

.invalid {
    border: 2px solid #d62e4f;
}
