.contentDiv {
    padding-bottom: 32px;
}

.contentSeparatorHr {
    margin-bottom: 32px;
}

.contentSeparatorDiv {
    padding-bottom: 32px;
}

.containerItem {
    padding-bottom: 32px;
}

.subTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 30px;
}

.divSeparator {
    padding-bottom: 5px;
}

.containerItemTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.containerItemDetail {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
    white-space: pre-wrap;
}

.nonValid {
    border-color: #d62e4f !important;
    color: #d62e4f;
}

.noEditText {
    text-align: justify;
    white-space: pre-wrap;
    width: 100%;
}

.contentTextArea {
    text-align: justify;
    white-space: pre-line;
    height: 200px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    padding-left: 16px;
    padding-top: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4c5357;
}

.verificationDetailsText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27221d;
}

.contentCheckbox {
    height: 18px;
    width: 18px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
}

.body {
    background: #ffffff;
    border-radius: 0 0 4px 4px;
    padding-left: 32px;
    padding-right: 32px;
}

.contentSeparatorHr {
    margin-bottom: 32px;
    margin-top: 0;
}
