/* General styles for different steps */
.container {
    margin-top: 25px;

    & label {
        margin-bottom: 10px;
        display: block;
        margin-top: 25px;
        font-weight: 600;
    }

    & p:nth-of-type(2) {
        margin: 25px 0 25px 0;
        font-weight: 600;
    }

    & input[type="date"] {
        height: 40px;
        width: 167px;
        padding-left: 16px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        background: #ffffff;
        opacity: 0.7;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
    }

    & input[type="text"] {
        height: 40px;
        width: 167px;
        padding-left: 16px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        background: #ffffff;
        opacity: 0.7;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
    }

    & select {
        height: 40px;
        width: 296px;
        border-radius: 4px;
        background: #ffffff;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        padding-left: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    & textarea {
        text-align: justify;
        white-space: pre-wrap;
        height: 80px;
        width: 550px;
        background: #ffffff;
        border: 1px solid #b2c2c8;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        resize: none;
        padding: 10px;
    }
}

.formButtons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    & button:first-of-type {
        margin-right: 10px;
    }
}

.stepContainer {
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 10px;
}

.response {
    color: #51585c;
    font-style: italic;
}

.headerEdit {
    display: flex;
    justify-content: space-between;
}

.editBtn {
    cursor: pointer;
    color: #0078ab;
    display: flex;
    align-items: center;

    & span {
        margin-left: 7px;
    }
}

.note {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.question {
    font-weight: 700;
    margin: 20px 0 10px 0;
    padding: 0;
    line-height: 24px;
}

.obligatory {
    color: #d62e4f;
    line-height: 24px;
    font-weight: 400;
}

.validationError {
    margin-bottom: 15px;
    color: #d62e4f;
    font-size: 14px;
    font-weight: 600;
}

.stepButton {
    margin: 12px 0 12px 0;
    box-shadow: inset 0 1px 4px rgba(#003349, 0), 0 0 0 3px rgba(#2c3439, 0);
    padding: 8px 24px;
    border-radius: 4px;
    min-width: fit-content;
    cursor: pointer;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
}

.nextButton {
    border-color: transparent;
    color: #fff;
    background-color: #0078ab;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
        color: #fff;
        background-color: #0078ab;
    }
}

.backButton {
    border-color: #0078ab;
    color: #0078ab;
    background-color: #fff;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
        border-color: #0078ab;
        color: #0078ab;
    }
}
.disabledButton {
    background-color: #707579;
    color: #fff;
    cursor: not-allowed;
    &:hover {
        background-color: #707579;
        color: #fff;
        cursor: not-allowed;
    }
}

.spinnerValidation {
    display: flex;
    margin-left: 22px;
    color: #0078ab;
    align-items: center;
}

.validatingMessage {
    margin-left: 10px;
}

.modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
}

.modalImage {
    width: 55%;
    margin-bottom: 25px;
}

.titleMessage {
    color: #003349;
    word-break: break-word;
}

.descriptionMessage {
    color: #2c3439;
    line-height: 24px;
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.closePageButton {
    border-color: transparent;
    color: #fff;
    background-color: #0078ab;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
        color: #fff;
        background-color: #0078ab;
    }
    width: 238px;
}

.radioOptions {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    & input {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1em;
        height: 1em;
        border: 2px solid #99adb6;
        border-radius: 50%;
        display: grid;
        place-content: center;
        outline: none;
        &::before {
            content: "";
            width: 0.5em;
            height: 0.5em;
            border-radius: 50%;
            transform: scale(0);
            box-shadow: inset 1em 1em #0078ab;
            transition: 120ms transform ease-in-out;
        }
        &:checked::before {
            transform: scale(1);
        }

        &:checked {
            border: 2px solid #0078ab;
        }
        :first-of-type {
            margin-bottom: 5px;
        }
    }
    & span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #373737;
    }
    :first-of-type,
    :nth-last-of-type(2) {
        margin-bottom: 7px;
    }
}

.radioContainer {
    display: -webkit-inline-box;
    flex-direction: row;
}

@media screen and (max-width: 768px) {
    .formButtons {
        display: block;
    }
    .spinnerValidation {
        margin: 15px 0 0 0;
    }
    select,
    textarea {
        width: 100% !important;
    }
    .radioOptions {
        :first-of-type,
        :nth-last-of-type(2) {
            margin-bottom: 7px;
        }
    }
}
