.tableItem {
    height: 48px;
    width: 1273px;
    margin-top: 8px;
    border-radius: 4px;
    background: #f5f7f8;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    padding-left: 23px;
}

.itemWithFilter {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    display: flex;
    margin-top: 5px;
    text-align: center;
}

.lastItem {
    justify-self: end;
    margin-right: 18px;
    display: flex;
    margin-top: 16px;
}

.item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    margin-top: 12px;
    text-transform: capitalize;
}

.itemTask {
    display: flex;
    align-items: center;
}

.arrowLink {
    text-decoration: none;
    color: #27221d;
}

.nameLink {
    text-decoration: none;
    color: #0078ab;
}

.assignedTo {
    cursor: pointer;
    margin-top: 7px !important;
}

.noDate {
    margin-left: 6px;
}

.textBold {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.unInitiated {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707579;
}
