.priorityGrid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 10px;
    cursor: pointer;
}

.dvRadio {
    padding-top: 3px;
    cursor: pointer;
}

.priorityRadio {
    box-sizing: border-box;
    background: #fbfbfc;
    border: 2px solid #99adb6;
}

.customClassBadge {
    display: inline-block;
    width: 103px;
    box-sizing: border-box;
    height: 24px;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    padding: 0.35rem 0.65rem;
    font-family: Nunito-sans, sans-serif;

    &.noPriority {
        color: #ffffff !important;
        background-color: #003349 !important;
    }
    &.lowPriority {
        color: #000000 !important;
        background-color: #ffe700 !important;
    }
    &.mediumPriority {
        color: #000000 !important;
        background-color: #ffad02 !important;
    }
    &.highPriority {
        color: #000000 !important;
        background-color: #e26c83 !important;
    }
}
