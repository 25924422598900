.financesTableTwoColumns {
    display: grid;
    grid-template-columns: 5fr 4fr;
}

.financesTableOneColumn {
    display: grid;
    grid-template-columns: 1fr 8fr;
}

.contentCheckbox {
    height: 18px;
    width: 18px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
}

.body {
    background: #ffffff;
    border-radius: 0 0 4px 4px;
    padding-left: 32px;
    padding-right: 32px;
}
