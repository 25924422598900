input[type="search"] {
    margin: 0;
    font-size: 14px;
    color: inherit;
    border-radius: 4px;
}

input[type="search"]::placeholder {
    color: #969a9c;
    font-size: 16px;
    padding-top: 20px;
}

input[type="search"]:focus {
    outline: none;
}

.inputSearch {
    width: 210px;
    height: 45px;
    border: 1px solid #b2c2c8;
    padding: 11px 4px 9px 40px;
    background: transparent
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23707579' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg>")
        no-repeat 13px center;
}
