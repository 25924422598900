.formView {
    width: 632px;
    max-width: 100%;
    margin: 48px;

    & h1 {
        font-family: "Public sans", sans-serif;
        font-weight: 800;
    }
}

//Mobile Section
@media screen and (max-width: 320px) {
    .formView {
        max-width: 50%;
        & h1 {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 480px) {
    .formView {
        width: 270px;
        & h1 {
            font-size: 20px;
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .formView {
        width: 420px;
        & h1 {
            font-size: 22px;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .formView {
        width: 632px;
        & h1 {
            font-size: 24px;
        }
    }
}

.container {
    width: 1360px;
    padding-left: 32px;
    border-radius: 4px;
}

.innerContainer {
    background: #ffffff;
    margin-top: 32px;
    padding-bottom: 40px;
    min-height: 700px;
}

.innerContainerTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
    padding-left: 32px;
    padding-top: 32px;
}

.titleSection {
    display: flex;
    justify-content: space-between;
}
