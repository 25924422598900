.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 50px;
    top: 130px;
}

.titleCompleted {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 10px;
    top: 130px;
}

.subTitleCompleted {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin-left: 50px;
    top: 130px;
    text-decoration-line: line-through;
    color: #2c3439;
}

.subTitle {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    top: 130px;
}

.subTitleInProgress {
    color: #6ba342;
    font-style: italic;
}

.subTitleNotInitiated {
    color: #d62e4f;
    font-style: italic;
}

.arrow {
    margin-left: 32px;
    margin-top: 10px;
    display: block;
}

.menuContainer {
    padding-left: 32px;
}
