.formContainer {
    margin-top: 30px;
    margin-bottom: 20px;

    & h3 {
        margin: 0;
        padding: 0;
        font-family: "Public sans", sans-serif;
        font-weight: 700;
        font-size: 24px;
        color: #2c3439;
    }
}
