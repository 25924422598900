.box {
    margin-top: 30px;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    color: #2c3439;
    margin-bottom: 20px;
}

.strongTitle {
    font-size: 15px;
    font-weight: 900;
    color: #2c3439;
}

.dateTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #2c3439;
    margin-top: 8px;
}
