.component {
    margin-top: 57px;
    background: #f5f7f8;
    border-radius: 4px;
    width: 483px;
    padding: 20px 27px 34px 24px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: #2c3439;
    padding-bottom: 20px;
}

.subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 10px;
}
.subtitleGrid {
    display: grid;
    grid-template-columns: 1fr 19fr;
    padding-bottom: 20px;
}

.contentText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27221d;
}

.linkSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.linkActive {
    font-weight: 600;
    color: #003349;
    border-bottom: 3px solid #99adb6;
}

.linkInactive {
    font-weight: 400;
    color: #4d7080;
    border-bottom: 1px solid #ccd6db;
}

.linkActive,
.linkInactive {
    align-self: end;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    font-style: normal;
}

.innerContent {
    padding-top: 10px;
    padding-left: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27221d;
}

.innerContentChk {
    height: 18px;
    width: 18px;
    left: 0px;
    top: 0px;
    border-radius: 3px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
}

.btn {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    margin-top: 30px;
}

.btnUpdateFollowUp {
    @extend .btn;
    width: 172px;
}

.btnAllowed {
    background: #0078ab;
    cursor: pointer;
}

.btnDisabled {
    background: #707579;
}

.internalTextArea {
    min-height: 221px;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    white-space: pre-wrap;
    padding: 11px 22px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    resize: none;
    justify-content: flex-start !important;
}

.internalTextAreaValid {
    border: 1px solid #b2c2c8;
    &:focus {
        outline: none !important;
        border: 2px solid #b2c2c8 !important;
    }
}

.internalTextAreaInvalid {
    border: 1px solid #d62e4f;
    &:focus {
        outline: none !important;
        border: 2px solid #d62e4f !important;
    }
}

.divSection {
    margin-top: 30px;
}

.btnBox {
    display: flex;
}

.followUpDueText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    margin-right: 10px;
}

.followUpDueUpdated {
    margin-left: 10px;
    color: green;
}

.followUpDueTextInactive {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707579;
}

.titleCompleted {
    color: #707579;
}

.searchingProgress {
    color: #0078ab;
    display: inline-block;
    margin-left: 5px;
    margin-top: 36px;
}

.checkboxAdjust {
    margin-top: 10px;
}

.dateSelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 20px;
}

.datepickerContainer {
    width: 200px;
}

.datepickerContainer input {
    height: 25px !important;
    text-align: right !important;
    font-size: 16px !important;
}

.dateTimeCustom {
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.label {
    margin-left: 10px;
    color: #737c83;
}
