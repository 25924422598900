.floatSearch {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    position: absolute;
    top: 20px;
    left: calc(10%);
    min-width: 196px;
    max-width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: initial;
    z-index: 10;
}
.close {
    text-align: right;
    font-weight: 100;
    margin-left: auto;
    border-radius: 50%;
    height: 20px;
    cursor: pointer;
}
.innerDiv {
    padding-top: 10px;
    padding-bottom: 10px;
}

.contentLink {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0078ab;
    cursor: pointer;
}
