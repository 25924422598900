.headerRowWithTitles {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.innerTable {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 8px;
    margin-bottom: 8px;
}

.showTitles {
    grid-template-columns: 1fr 4fr;
}

.showTitlesWideBox {
    grid-template-columns: 1fr 8fr;
}

.hideTitles {
    grid-template-columns: 1fr;
}

.headerBox {
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    text-align: center;
    margin-bottom: 16px;
}

.header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: auto;
}

.divSeparator {
    padding-bottom: 5px;
}

.containerItem {
    padding-bottom: 32px;
}

.rowTitle {
    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
    max-width: 90px;
}

.rowContent {
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 12px;
    background: #f5f7f8;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    height: 50px;
}

.rowContentHighlighted {
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 12px;
    background: #f5f7f8;
    border-radius: 4px;
    border: 1px solid #ccd6db;
    font-size: 16px;
    line-height: 24px;
    height: 50px;
}

.leftMarginContent {
    margin-left: 4px;
}

.rightMarginContent {
    margin-right: 4px;
}
