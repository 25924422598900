.inputResolveBulk {
    width: 858px;
    border: 1px solid #b2c2c8;
    padding-left: 15px;
    border-radius: 4px;
    font-family: "Public sans", sans-serif;
    font-size: 16px;
    &::placeholder {
        color: #969a9c !important;
        opacity: 1;
    }
}

.bulkContainer {
    display: flex;
}

.bulkButton {
    width: 104px;
    height: 42px;
    border-radius: 4px;
    color: #ffffff;
    border: none;
    font-size: 16px;
    margin-left: 32px;
    margin-right: 848px;
}

.buttonDisabled {
    background-color: #707579;
    cursor: not-allowed;
}

.buttonEnabled {
    background-color: #0078ab;
    cursor: pointer;
}

.alert {
    position: absolute;
    top: 61px;
    left: 324px;
}

.cancellingMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
}

.message {
    margin-left: 1.5rem;
}

.cases {
    color: #e26c83;
}

.toText {
    margin: 10px 18px 0 0;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}
