#components-table-demo-drag-sorting tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}

#dateTextDivisor {
    padding-top: 25px;
    padding-left: 3px;
}

.radio {
    padding-top: 5px;
}

.btn {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    margin-bottom: 15px;
}

.buttonAdd {
    @extend .btn;
    display: flex;
    justify-content: flex-end;
    width: 140px;
    background: #0078ab;
}

.btnSave {
    @extend .btn;
    width: 208px;
    background: #0078ab;
}

.btnClear {
    @extend .btn;
    width: 133px;
    color: #0078ab;
    border: 1px solid #0078ab;
    margin-right: 15px;
}

.dateCustom {
    height: 40px;
    width: 150px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}

.icon {
    display: flex;
    opacity: 0.9;
    font-size: 22px;
    color: #2c3439;
}

.rangePicker {
    width: 350px;
}
