.headerRowWithTitles {
    display: grid;
    grid-template-columns: 2fr 8fr;
}

.header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: auto;
}

.headerBox {
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    text-align: center;
    margin-bottom: 16px;
}

.flexTitle {
    display: flex;
    position: relative;
}

.arrowCircle {
    box-sizing: border-box;
    background: #ffffff;
    border: 0.5px solid #e5ebed;
    box-shadow: 0 0 4px rgba(44, 52, 57, 0.08);
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

.leftArrow {
    position: absolute;
    left: 10px;
}

.rightArrow {
    position: absolute;
    right: 10px;
}

.arrowLink {
    text-decoration: none;
    color: #27221d;
    vertical-align: middle;
    margin-bottom: 2px;
}
