.skeleton {
    background: #eeeeee;
    margin-left: 10px;
    border-radius: 5px;
    animation: skeleton-animation 0.5s linear infinite alternate;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.smallText {
    width: 15%;
    height: 20px;
}

.skeleton.mediumText {
    width: 40%;
    height: 20px;
}

.skeleton.largeText {
    width: 100%;
    height: 20px;
}

.skeleton.separator {
    width: 100%;
    height: 5px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}

@keyframes skeleton-animation {
    0% {
        background-color: hsl(0, 0%, 70%);
    }
    100% {
        background-color: hsl(0, 0%, 90%);
    }
}
