.splitstayContainer {
    background-color: #f5f7f8;
    padding: 1.5rem;
    width: 27.25rem;
    border-radius: 0.4rem;

    &:nth-child(-n + 5) {
        margin: 1.6rem 0 1.6rem 0;
    }
}

.datesContainer {
    display: flex;
    margin: 1.5rem 0 1.5rem 0;
    & > div > div {
        background-color: white !important;
        border-radius: 4px !important;
    }
}

.dates {
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
        margin-left: 2.6rem;
    }
    & input {
        width: 100px !important;
        height: 25px !important;
        text-align: right !important;
        font-size: 1rem !important;
    }
}

.datesTitle {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.bookingTypeContainer {
    margin: 0 0 2rem 0;
}

.bookingTypeTitle {
    color: #2c3439;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.vacasaUnitSection {
    display: flex;
    flex-direction: column;
}

.outsideBookingSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    & > div > div > div > div {
        background-color: white;
        border-radius: 4px;
    }
}

.subTitles {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.urlOutsideBooking {
    margin-top: 23px;
}

.textBoxWrapUp {
    height: 40px;
    width: 167px;
    padding-left: 16px;
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 24px;
}

.textBox {
    height: 40px;
    width: 100%;
    padding-left: 16px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.unitNumber {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.resIdPlain,
.urlPlain {
    cursor: pointer;
    color: #0078ab;
    text-decoration: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.datesPlain {
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #4c5357;
}

.urlTitle {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    line-height: 1.8rem;
}

.urlTitlePlain {
    margin-top: 1.5rem;
}

.checkboxTypeOfBooking {
    margin-left: 20px;
}

.alignRight {
    text-align: right;
}

.editBtn {
    vertical-align: middle;
    color: #707579;
    cursor: pointer;
}

.subTitleGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.textBoxWarning {
    border: 1px solid #d62e4f;
    &:focus {
        outline: none !important;
        border: 2px solid #d62e4f !important;
    }
}

.textBoxWarningText {
    color: #d62e4f;
}
