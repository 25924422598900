.noteText {
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px;
}

.clearSubmission {
    cursor: pointer;
    margin: 0 0 0 15px;
    align-self: center;
    color: #0078ab;
}

.submitAnotherFm {
    cursor: pointer;
    margin: 15px 0 0 15px;
    align-self: center;
    color: #0078ab;
}
