.bulkSelectionContainer {
    width: 350px;
    max-height: 947px;
    overflow-y: auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    color: #000;
    position: absolute;
    right: 30px;
    z-index: 10;
    border-radius: 5px;
    border: 1px solid #a8a8a8;

    .inner {
        padding: 0 32px 32px 32px;
    }

    .bulkHeader {
        position: sticky;
        top: 0;
        background: #ffffff;
        padding: 32px 32px 0 32px;
    }

    .link {
        font-style: normal;
        font-weight: 600;
        color: #0078ab;
        cursor: pointer;
    }

    .statsSection {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .noSelected {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        color: #4d7080;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.3px;
        color: #2c3439;
        margin: auto;
    }

    .headerCases {
        height: 30px;
        background: #e5ebed;
        box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
        margin-bottom: 17px;
        padding-left: 5px;
        display: grid;
        grid-template-columns: 1.3fr 1.2fr 1.5fr 0.4fr;
        grid-gap: 5px;
    }

    .headerItem {
        font-family: "Public Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #2c3439;
        margin-top: 5px;
    }

    .unselectBtn {
        text-decoration: none;
        color: #27221d;
        margin-right: 5px;
        display: flex;
    }

    .table {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #2c3439;

        .item {
            height: 30px;
            margin-top: 8px;
            border-radius: 8px;

            display: grid;
            grid-template-columns: 1.3fr 1.2fr 1.5fr 0.4fr;
            grid-gap: 5px;
            align-items: center;
        }

        .tableItem {
            background: #f5f7f8;
        }

        .itemTask {
            display: flex;
            align-items: center;
        }
    }
}

.customClassBadge {
    display: inline-block;
    box-sizing: border-box;
    height: 20px;
    font-size: 10px;
    font-weight: 800;
    line-height: 10px;
    margin-left: 5px;
    padding: 0.35rem 0.65rem;
    font-family: Nunito-sans, sans-serif;

    &.noPriority {
        color: #ffffff !important;
        background-color: #003349 !important;
    }
    &.lowPriority {
        color: #000000 !important;
        background-color: #ffe700 !important;
    }
    &.mediumPriority {
        color: #000000 !important;
        background-color: #ffad02 !important;
    }
    &.highPriority {
        color: #000000 !important;
        background-color: #e26c83 !important;
    }
    &.isLTR {
        color: #000000 !important;
        background-color: #a7c7e7 !important;
        width: 40px !important;
        font-size: 10px !important;
        font-weight: 600 !important;
        margin-top: -10px !important;
        margin-left: -10px !important;
    }
}
