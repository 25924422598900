@mixin button {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    height: 40px;
    background: #0078ab;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

@mixin button-secondary {
    @include button;
    color: #0078ab;
    background: #ffffff;
}

@mixin select {
    height: 40px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    padding-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@mixin input {
    text-align: right;
    height: 40px;
    width: 110px;
    padding-left: 16px;
    padding-right: 8px;
    background: #ffffff;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #2c3439;
}
