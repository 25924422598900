.taskGrid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 10px;
    cursor: pointer;
}

.dvRadio {
    padding-top: 3px;
    cursor: pointer;
}

.taskRadio {
    box-sizing: border-box;
    background: #fbfbfc;
    border: 2px solid #99adb6;
}
